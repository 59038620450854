/* eslint-disable no-console */
import { parse, format } from 'url';

import React from 'react';
import unescape from 'lodash/unescape';

import { CONTEXT_PATH, MY_SAVED_FOR_LATER } from '@confluence/named-routes';
import { LazyAddPersonalSpaceDialog } from '@confluence/add-personal-space-dialog';
import { setUserAgent, friendlyName, version } from '@confluence/browser-helper';

import { LazyAboutConfluenceDialog } from './AboutConfluenceDialog/LazyAboutConfluenceDialog';
import { LazyGetMobileAppDialog } from './GetMobileAppDialog/LazyGetMobileAppDialog';
import { LazyGiveFeedbackDialog as GiveFeedbackDialog } from './GiveFeedbackDialog/LazyGiveFeedbackDialog';
import type { PopupMenuItem } from './PopupMenuItem';
import { ThemeMenuItem } from './ThemeMenuItem';

setUserAgent();

export type WebItem = {
	accessKey?: string;
	completeKey: string;
	hasCondition: boolean;
	icon?: string;
	id: string;
	label: string;
	moduleKey?: string;
	params?: Partial<{
		target: string;
	}>;
	parentSection: Partial<{
		label: string;
	}>;
	section: string;
	styleClass: string;
	tooltip: string;
	url: string;
	urlWithoutContextPath: string;
	webSection: string;
	weight: number;
};

const addQueryParamsToUrl = function addQueryParamsToUrl(params, url) {
	const parsedUrl = { ...parse(url, true), search: undefined };

	parsedUrl.query = Object.assign({}, parsedUrl.query, params);
	return format(parsedUrl);
};

const cleanURL = (url, userId) =>
	url.replace('$generalUtil.doubleUrlEncode($helper.action.authenticatedUser.name)', userId);

/**
 * A connect add-on web-item with `ap-dialog` or `ap-inline-dialog` class name will trigger opening Connect Dialog.
 * @param styleClass
 * @returns {boolean}
 */
const isConnectWebItemWillTriggerDialog = (styleClass) =>
	styleClass.indexOf('ap-dialog') >= 0 || styleClass.indexOf('ap-inline-dialog') >= 0;

/**
 * Add `action` for a Atlaskit dropdown menu item so that when dropdown menu item is selected, `action` is called and trigger click event on anchor element automatically.
 * @param {Object} item - dropdown menu object. A new `action` attribute will be added.
 * @returns {Function} a callback function which is used to pass `ref` prop.
 */
const createClickActionHandlerForProxyAnchor = (item) => {
	return (anchorEl) => {
		item.action = () => anchorEl.click();
	};
};

/**
 * createFakeWebDriverTarget will add an invisible dom element in the dropdown item, which allows old pageobjects of
 * AUIDropdown to target atlaskit dropdowns. Hopefully it's temporary.
 *
 * @param id
 */
const createFakeWebDriverTarget = (id) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/design-system/use-primitives-text -- Ignored via go/DSP-18766
	<span id={id} style={{ opacity: 0 }} aria-hidden>
		-
	</span>
);

const getMobileAppAnalyticsEvent = {
	type: 'sendUIEvent',
	data: {
		action: 'clicked',
		actionSubject: 'navigationMenuItem',
		actionSubjectId: 'getMobileApp',
		objectType: null,
		objectId: null,
		source: 'globalNavigation',
		attributes: {
			browser: `${friendlyName()}-${version()}`,
		},
	},
};

const createAboutConfluenceDialog = (onClose) => <LazyAboutConfluenceDialog onClose={onClose} />;

const createGetMobileAppDialog = (onClose) => (
	<LazyGetMobileAppDialog onClose={onClose} analyticsEvent={getMobileAppAnalyticsEvent} />
);

const createGiveFeedbackDialog = (onClose) => <GiveFeedbackDialog onClose={onClose} />;

const createAddPesonalSpaceDialog = (onClose) => <LazyAddPersonalSpaceDialog onClose={onClose} />;

export type MappedWebItem = {
	getDialog: (onClose: () => void) => React.ReactElement;
	iconAfter: React.ReactElement;
	content: React.ReactElement | string;
	action: () => void;
	closeAction?: () => void; // Custom on close handler (invoked after click/action)
	title: string;
	target: string;
	href: string;
	key: string;
	component?: React.ElementType<PopupMenuItem>; // Use to override the default use of LinkItem
};

type MappedWebItemPartial = Partial<MappedWebItem> | null;

export const mapWebItem = (
	{ url, params, label, moduleKey, id = '', tooltip = '', styleClass = '' }: WebItem,
	push: (url) => void,
	userId: string | null | undefined,
	callbackAction?: () => void,
) => {
	label = unescape(label);
	switch (moduleKey) {
		case 'confluence-about':
			const about: MappedWebItemPartial = {
				key: moduleKey,
				getDialog: createAboutConfluenceDialog,
				iconAfter: createFakeWebDriverTarget('confluence-about-link'),
				content: label,
			};
			return about;
		case 'confluence-mobile-app-help-menu':
			return {
				key: moduleKey,
				getDialog: createGetMobileAppDialog,
				iconAfter: createFakeWebDriverTarget('confluence-get-mobile-app'),
				content: label,
			};
		//we are removing gadgets
		case 'gadget-directory':
			const gadget: MappedWebItemPartial = null;
			return gadget;
		case 'user-history':
			const userHistory: MappedWebItemPartial = {
				key: moduleKey,
				action: () => console.log('open ViewRecentsModalContainer'),
				content: label,
			};
			return userHistory;
		case 'user-profile':
			const additionalQueryParamsForAnalytics = 'src=usermenu';
			const userProfileUrl = `${CONTEXT_PATH}/people/${userId}?${additionalQueryParamsForAnalytics}`;
			return {
				key: moduleKey,
				href: userProfileUrl,
				content: label,
				title: tooltip,
			};
		case 'keyboard-shortcuts-dialog-help-menu':
			const keyboardShortcuts: MappedWebItemPartial = {
				key: moduleKey,
				action: callbackAction,
				iconAfter: createFakeWebDriverTarget('keyboard-shortcuts-link'),
				content: label,
			};
			return keyboardShortcuts;
		case 'create-personal-space':
			const createPersonalSpace: MappedWebItemPartial = {
				key: moduleKey,
				getDialog: createAddPesonalSpaceDialog,
				content: label,
			};
			return createPersonalSpace;
		case 'personal-space':
			const personalSpace: MappedWebItemPartial = {
				key: moduleKey,
				content: label,
				target: '_self',
				href: url,
				title: tooltip,
			};
			return personalSpace;
		case 'admin-restore-personal-space':
			const adminRestorePersonalSpace: MappedWebItemPartial = {
				key: moduleKey,
				action: callbackAction,
				content: label,
				title: tooltip,
			};
			return adminRestorePersonalSpace;
		case 'my-tasks-menu-item':
			const task: MappedWebItemPartial = {
				key: moduleKey,
				content: label,
				target: '_self',
				href: `${CONTEXT_PATH}/home/tasks`,
				title: tooltip,
			};
			return task;
		case 'adg3-give-feedback':
			const giveFeedback: MappedWebItemPartial = {
				key: moduleKey,
				getDialog: createGiveFeedbackDialog,
				content: label,
			};
			return giveFeedback;
		case 'user-favourites':
			const userFavourites: MappedWebItemPartial = {
				key: moduleKey,
				content: label,
				target: '_self',
				href: MY_SAVED_FOR_LATER.toUrl(),
				title: tooltip,
			};
			return userFavourites;
		case 'user-theme':
			const userThemePreference: MappedWebItemPartial = {
				key: moduleKey,
				component: ThemeMenuItem,
				content: label,
				title: tooltip,
				action: callbackAction,
				closeAction: () => {}, // No-op, don't close
			};
			return userThemePreference;
		case 'login':
			const { pathname, search, hash } = window.location;
			let currentLocation = pathname + search + hash;
			if (currentLocation.startsWith(CONTEXT_PATH)) {
				currentLocation = currentLocation.slice(CONTEXT_PATH.length);
			}
			const login: MappedWebItemPartial = {
				key: moduleKey,
				href: addQueryParamsToUrl(
					{
						os_destination: currentLocation,
					},
					url,
				),
				content: label,
			};
			return login;
		case 'logout':
			const logout: MappedWebItemPartial = {
				key: moduleKey,
				action: () => {
					push(url);
				},
				content: label,
			};
			return logout;
		default:
			const item: MappedWebItemPartial = {
				key: moduleKey,
				href: cleanURL(url, userId),
				target: (params && params.target) || '_self',
				content: label,
				title: tooltip,
			};

			const shouldRenderProxyAnchor = isConnectWebItemWillTriggerDialog(styleClass);
			item.iconAfter = (
				<span>
					{/* render an empty span with id so that WD tests can query it.*/}
					{id ? createFakeWebDriverTarget(id) : null}
					{/* because @atlaskit/dropdown-menu does not support to render custom classes, so here is a workaround to fix that. */}
					{shouldRenderProxyAnchor ? (
						// eslint-disable-next-line jsx-a11y/anchor-has-content
						<a
							href={item.href}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={styleClass}
							ref={createClickActionHandlerForProxyAnchor(item)}
						/>
					) : null}
				</span>
			);

			if (shouldRenderProxyAnchor) {
				// `item.action` value will be overridden by `ref` callback.

				// prevent rendering anchor element for dropdown menu item because there is a above proxy anchor
				// which helps to trigger a click event when dropdown menu item is selected.
				item.href = '';
				item.target = '';
			}

			return item;
	}
};
