import { defineMessages } from 'react-intl-next';
import groupBy from 'lodash/groupBy';

import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';

import type { WebItem, MappedWebItem } from './mapWebItem';
import { mapWebItem } from './mapWebItem';

// eslint-disable-next-line check-i18n-id-prefix/i18n-id-prefix-with-package-name-defineMessages
const i18n = defineMessages({
	'system.user/adg3-section': {
		id: 'navigation.help.us.improve',
		defaultMessage: 'Help us improve',
	},
	'system.user/user-content': {
		id: 'navigation.adg3.user.profile.user-content',
		defaultMessage: 'Confluence',
	},
	'system.help/pages': {
		id: 'navigation.help.name',
		defaultMessage: 'Help',
	},
	'system.help/pages/legal-section': {
		id: 'navigation.system.help.legal',
		defaultMessage: 'Legal',
	},
});

type GroupedWebItems = {
	[key: string]: WebItem[];
};

export type FilteredItems = {
	heading:
		| string
		| {
				id: string;
				defaultMessage: string;
		  }
		| null;
	items: Array<Partial<MappedWebItem>>;
};

type Items = Array<FilteredItems>;

interface WebItemMapperOptions {
	userId?: string;
	callbackActions?: {
		[moduleKey: string]: () => void;
	};
}

export const useWebItemMapper = (
	webItems: WebItem[],
	webSections: (string | null)[],
	options: WebItemMapperOptions,
) => {
	const { push } = useRouteActions();
	const items: Items = [];

	if (webItems && webItems.length) {
		const groupedSections: GroupedWebItems = groupBy(webItems, 'section');
		webSections.forEach((sectionName) => {
			if (!sectionName) {
				return;
			}

			const webItemRecords = groupedSections[sectionName];

			if (!webItemRecords) {
				return;
			}

			const tempItems = webItemRecords
				.map((webItemRecord) =>
					mapWebItem(
						webItemRecord,
						push,
						options?.userId,
						webItemRecord.moduleKey && options?.callbackActions
							? options?.callbackActions[webItemRecord.moduleKey]
							: undefined,
						// isTaskHomeEnabled
					),
				)
				.filter((item): item is Partial<MappedWebItem> => !!item);

			items.push({
				heading: i18n.hasOwnProperty(sectionName) ? { ...i18n[sectionName] } : null,
				items: tempItems,
			});
		});
	}

	return items;
};
