import { LoadableLazy } from '@confluence/loadable';

export const LazyAboutConfluenceDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AboutConfluenceDialog" */ './AboutConfluenceDialog'
			)
		).AboutConfluenceDialog,
});
