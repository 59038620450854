import gql from 'graphql-tag';

export const HardDeleteSpaceLongTaskQuery = gql`
	query HardDeleteSpaceLongTaskQuery($id: ID!) {
		confluence {
			longTask(id: $id) {
				id
				state {
					elapsedTime
					name
					__typename
				}
			}
		}
	}
`;
