import React, { useCallback, useContext, useState } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { UpFlowContext } from '@confluence/change-edition/entry-points/UpFlowProvider';

import { PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES } from './constants';
import { constructUIEvent } from './constructPersistentUpgradeAnalyticsEvents';
import {
	EditionTrialType2PersistentUpgradeMenuIdMap,
	PersistentUpgradeMenuItem,
	constructPreTrialAnalyticsEventPayload,
	messages,
} from './PersistentUpgradeMenuItem';
import { useTrialPlanComparisonModalActions } from './TrialPlanComparisonExperiment/useTrialPlanComparisonModal';

export const usePersistentUpgradeMenuSection = () => {
	const [renderFailed, setRenderFailed] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { currentEdition, cloudId, isSiteAdmin } = useContext(UpFlowContext);

	const { showModal: showTrialComparisonModal } = useTrialPlanComparisonModalActions();

	const onUpgradeMenuItemClick = useCallback(() => {
		createAnalyticsEvent(
			constructUIEvent({
				eventAction: 'clicked',
				...constructPreTrialAnalyticsEventPayload(currentEdition!),
			}),
		).fire();

		void showTrialComparisonModal({
			touchpointId:
				EditionTrialType2PersistentUpgradeMenuIdMap[
					PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES.PRE_TRIAL
				],
		});
	}, [createAnalyticsEvent, currentEdition, showTrialComparisonModal]);

	const shouldRenderPersistentUpgradeMenuSection =
		currentEdition === ConfluenceEdition.FREE &&
		Boolean(isSiteAdmin) &&
		Boolean(cloudId) &&
		!renderFailed;

	if (shouldRenderPersistentUpgradeMenuSection) {
		const menuSection = {
			heading: messages.persistentUpgradeMenuItemFreePlanTitle,
			items: [
				{
					action: onUpgradeMenuItemClick,
					content: (
						<TransparentErrorBoundary
							attribution={Attribution.GROWTH_LUNA}
							onError={() => setRenderFailed(true)}
						>
							<PersistentUpgradeMenuItem />
						</TransparentErrorBoundary>
					),
				},
			],
		};
		return [menuSection];
	} else {
		return [];
	}
};
