import React, { useCallback, useContext, useState } from 'react';
import { defineMessages } from 'react-intl-next';
import differenceInDays from 'date-fns/differenceInDays';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { EditionAwarenessStandardPubTouchpoint } from '@atlassian/edition-awareness-standard-pub-touchpoint';

import { expValEqualsNoExposure } from '@confluence/feature-experiments';
import { getAGGClient, isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';
import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { SPAViewContext } from '@confluence/spa-view-context';
import { ConfluenceEdition, useSessionData } from '@confluence/session-data';

import { IsTenantPaidAndUpgradable } from './graphql/IsTenantPaidAndUpgradable.agggraphql';
import { useConfluenceStandardToPremiumPillExperiment } from './useConfluenceStandardToPremiumPillExperiment';
import { useConfluenceStandardToPremiumModalActions } from './useConfluenceStandardToPremiumModal';
import { useBuildLearnMoreLink } from './use-build-learn-more-link';

export const messages = defineMessages({
	persistentUpgradeMenuItemFreePlanTitle: {
		id: 'experiment-edition-awareness-for-standard-to-premium.section.message.persistent-upgrade.menuitem.premiumplan.title',
		defaultMessage: 'Upgrade',
		description:
			'Persistent upgrade touchpoint for Edition Awareness Standard to Premium experiment',
	},
});

function getTrialDaysLeft(trialEndDate?: number | null) {
	if (!trialEndDate) {
		return undefined;
	}
	// +1 day to round up for fractional days
	return differenceInDays(new Date(trialEndDate), new Date()) + 1;
}
export const useConfluenceStandardToPremiumPUBTouchpoint = () => {
	const [renderFailed, setRenderFailed] = useState(false);
	const { cloudId, edition } = useSessionData();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { isDismissed } = useConfluenceStandardToPremiumPillExperiment();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { showModal, setModalProps } = useConfluenceStandardToPremiumModalActions();

	const meetsBaseEligibility =
		isDismissed &&
		isSiteAdmin &&
		edition === ConfluenceEdition.STANDARD &&
		// Exposure fired earlier in experiment when they see EA
		// eslint-disable-next-line confluence-feature-gating/no-exposure-recommendation
		expValEqualsNoExposure(
			'edition_awareness_standard_upsell_confluence_ab',
			'cohort',
			'treatment',
		);

	const { data, error, loading } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		IsTenantPaidAndUpgradable,
		{
			client: getAGGClient(),
			variables: {
				cloudId,
				productKey: 'confluence',
				trialOfferingName: 'premium',
				shouldCheckForPaidOffering: meetsBaseEligibility,
				entitlementFilter: null,
			},
			skip: !meetsBaseEligibility,
		},
	);

	if (error && !isErrorMarkedAsHandled(error)) {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'failed',
				actionSubject: 'persistent-upgrade-button-standard-to-premium',
				source: 'useConfluenceStandardToPremiumPUBTouchpoint',
				attributes: {
					growthFeature: 'EditionAwareness',
					error: error.message,
				},
			},
		}).fire();
		markErrorAsHandled(error);
	}

	const entitlement = data?.tenantContexts?.[0]?.entitlementInfo?.entitlement;
	const paidEntitlement = data?.tenantContexts?.[0]?.entitlementInfo?.paidEntitlement;
	const isInvoiceable = paidEntitlement?.subscription?.accountDetails?.invoiceGroup?.invoiceable;
	const trialDaysLeft = getTrialDaysLeft(entitlement?.subscription?.trial?.endTimestamp);

	const isInTrial = Boolean(trialDaysLeft && trialDaysLeft > 0);
	const isInPredunning = entitlement?.preDunning?.status === 'IN_PRE_DUNNING' && !isInvoiceable;
	const canUpgradePaidEntitlement = Boolean(
		paidEntitlement?.experienceCapabilities?.changeOfferingV2?.isAvailableToUser &&
			paidEntitlement?.experienceCapabilities?.changeOfferingV2?.experienceUrl,
	);
	const isEligibleForStandardToPremiumPersistentUpgradeButton =
		!isInTrial &&
		!isInPredunning &&
		isInvoiceable &&
		canUpgradePaidEntitlement &&
		meetsBaseEligibility &&
		!renderFailed;

	const experienceUrl: string | undefined | null =
		paidEntitlement?.experienceCapabilities?.changeOfferingV2?.experienceUrl;

	if (
		isEligibleForStandardToPremiumPersistentUpgradeButton &&
		(!experienceUrl || experienceUrl?.includes('admin/billing'))
	) {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'error',
				actionSubject: 'dialog',
				actionSubjectId: 'editionAwarenessStandardToPremiumDialog',
				attributes: {
					product: 'confluence',
					touchpointIntent: 'startTrial',
					dialogId: 'standard-to-premium',
					dialogType: 'modal',
					error: 'No change offering URL found for premium upgrade - using fallback',
					changeOfferingUrl: experienceUrl ?? '',
					isEligibleForStandardToPremiumPersistentUpgradeButton,
				},
				source: 'useConfluenceStandardToPremiumPUBTouchpoint',
			},
		}).fire();
	}

	// remove the edition from the change plan urls so they land on the plan selection page for hams || ccp
	// there may be a key in shared tenant context api that can be used instead of this
	const learnMoreUrl = useBuildLearnMoreLink(experienceUrl);

	const handlePUBClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'persistent-upgrade-button-standard-to-premium',
				attributes: {
					growthFeature: 'EditionAwareness',
				},
				source: 'useConfluenceStandardToPremiumPUBTouchpoint',
			},
		}).fire();

		void setModalProps({
			experienceUrl: experienceUrl ?? '',
			learnMoreUrl,
		});

		void showModal({
			touchpointId: 'edition-awareness-standard-to-premium-pub',
		});
	}, [createAnalyticsEvent, showModal, setModalProps, experienceUrl, learnMoreUrl]);

	if (!isEligibleForStandardToPremiumPersistentUpgradeButton || loading || error) {
		return [];
	}

	return [
		{
			heading: { ...messages.persistentUpgradeMenuItemFreePlanTitle },
			items: [
				{
					action: () => handlePUBClick(),
					content: (
						<TransparentErrorBoundary
							attribution={Attribution.GROWTH_LUNA}
							onError={() => setRenderFailed(true)}
						>
							<EditionAwarenessStandardPubTouchpoint
								product="confluence"
								testId="edition-awareness-standard-pub-touchpoint"
							/>
						</TransparentErrorBoundary>
					),
					key: 'try-premium-plan',
				},
			],
		},
	];
};
