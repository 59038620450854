import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { LinkItem } from '@atlaskit/menu';
import type { CSSFn } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';

import type { MappedWebItem } from './mapWebItem';

export interface PopupMenuItem {
	item: Partial<MappedWebItem>;
	isSelected: boolean;
	onClick: () => void;
	onKeyPress: (event: KeyboardEvent) => void;
	tabIndex: number | undefined;
	role: string | undefined;
	cssFn?: CSSFn;
	closeMenu: () => void;
}

type PopupMenuItemProps = {
	item: Partial<MappedWebItem>;
	onClickHandler: () => void;
	isSelected: boolean;
	closeMenu: () => void;
};

export const CSSFunction = () => ({
	'&:visited, &:active': {
		color: token('color.text'),
		textDecoration: 'none',
	},
});
export const PopupMenuItemComponent: FC<PopupMenuItemProps> = ({
	item,
	onClickHandler,
	closeMenu,
	isSelected,
}) => {
	const ItemComponent = item.component;

	const onKeyPress = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Enter' || event.key === 'Space') {
				onClickHandler();
			}
		},
		[onClickHandler],
	);

	const commonProps = useMemo(() => {
		const tabIndex = item.href ? undefined : 0;
		const role = item.href ? undefined : 'link';

		return {
			cssFn: CSSFunction,
			onClick: onClickHandler,
			closeMenu,
			isSelected,
			// The following 3 attributes are needed for accessibility
			// Otherwise, a link item's onClick is not usable by keyboard
			onKeyPress,
			// Only needed if the href attribute is missing, for accessibility
			tabIndex,
			role,
		};
	}, [onClickHandler, isSelected, onKeyPress, item, closeMenu]);

	return ItemComponent ? (
		<ItemComponent item={item} {...commonProps} />
	) : (
		<LinkItem
			testId="default-popup-menu-item"
			{...(item.href && { href: item.href })}
			target={item.target || undefined}
			iconAfter={item.iconAfter || null}
			{...commonProps}
		>
			{item.content}
		</LinkItem>
	);
};
