import type { ContextType, FC } from 'react';
import React, { useEffect, useContext } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Lozenge from '@atlaskit/lozenge';

import { UpFlowContext } from '@confluence/change-edition/entry-points/UpFlowProvider';

import { PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES, PERSISTENT_UPGRADE_FLOWID } from './constants';
import { constructUIEvent } from './constructPersistentUpgradeAnalyticsEvents';

export const UI_EVENT_TYPE = 'sendUIEvent';
export const OPERATIONAL_EVENT_TYPE = 'sendOperationalEvent';

export const PERSISTENT_UPGRADE_TOUCHPOINT_MENU = 'persistentUpgradeMenu';
export const PERSISTENT_UPGRADE_PRETRIAL_MENUID = 'preTrialMenu';

export const EditionTrialType2PersistentUpgradeMenuIdMap: {
	[index: string]: string;
} = {
	[PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES.PRE_TRIAL]: PERSISTENT_UPGRADE_PRETRIAL_MENUID,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuItemContent = styled.div({
	display: 'flex',
	flexWrap: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeContainer = styled.div({
	margin: `-1px 0px 0px ${token('space.100')}`,
});

export const messages = defineMessages({
	persistentUpgradeMenuItemFreePlanTitle: {
		id: 'persistent-upgrade.section.message.persistent-upgrade.menuitem.freeplan.title',
		defaultMessage: 'UPGRADE',
		description: 'Account profile menu item title for free plan',
	},
	persistentUpgradeMenuItemFreePlanLabelExperiment: {
		id: 'persistent-upgrade.trial-plan-comparison.section.message.persistent-upgrade.menuitem.freeplan.label',
		defaultMessage: 'Try Standard or Premium',
		description: 'Account profile menu item label for free plan.',
	},
	persistentUpgradeMenuItemFreePlanLozengeExperiment: {
		id: 'persistent-upgrade.trial-plan-comparison.section.message.persistent-upgrade.menuitem.freeplan.lozenge',
		defaultMessage: 'FREE TRIAL',
		description: 'Account profile menu item lozenge for free plan',
	},
});

export const PersistentUpgradeMenuItem: FC = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { currentEdition } = useContext(UpFlowContext);

	useEffect(() => {
		if (currentEdition) {
			createAnalyticsEvent(
				constructUIEvent({
					eventAction: 'viewed',
					...constructPreTrialAnalyticsEventPayload(currentEdition),
				}),
			).fire();
		}
	}, [createAnalyticsEvent, currentEdition]);

	return (
		<MenuItemContent>
			<FormattedMessage {...messages.persistentUpgradeMenuItemFreePlanLabelExperiment} />
			<LozengeContainer>
				<Lozenge appearance="inprogress" isBold>
					<FormattedMessage {...messages.persistentUpgradeMenuItemFreePlanLozengeExperiment} />
				</Lozenge>
			</LozengeContainer>
		</MenuItemContent>
	);
};

export const constructPreTrialAnalyticsEventPayload = (
	currentEdition: ContextType<typeof UpFlowContext>['currentEdition'],
) => ({
	touchpointSubject: PERSISTENT_UPGRADE_TOUCHPOINT_MENU,
	currentEdition,
	touchpointId:
		EditionTrialType2PersistentUpgradeMenuIdMap[PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES.PRE_TRIAL],
	flowId: PERSISTENT_UPGRADE_FLOWID,
});
