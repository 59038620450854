import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	useSetOnboardingState,
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';

export const EDITION_AWARENESS_STANDARD_TO_PREMIUM_EXPERIMENT_PILL_DISMISSED =
	'growth-luna-edition-awareness-standard-to-premium:treatmentPillDismissed';

export const useConfluenceStandardToPremiumPillExperiment = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { setOnboardingState } = useSetOnboardingState();

	// fetch dismiss state for EA pill
	const { data, error, loading } = useGetOnboardingState(
		EDITION_AWARENESS_STANDARD_TO_PREMIUM_EXPERIMENT_PILL_DISMISSED,
		!fg('edition_awareness_upsell_confluence_aa_fg') &&
			!fg('edition_awareness_standard_upsell_confluence_ab_fg'),
	);

	if (error && !isErrorMarkedAsHandled(error)) {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'failed',
				actionSubject: 'onboarding-state',
				source: 'useConfluenceStandardToPremiumPillExperiment',
				attributes: {
					growthFeature: 'EditionAwareness',
					error: error.message,
				},
			},
		}).fire();
		markErrorAsHandled(error);
	}

	const { treatmentPillDismissed } = deserializeState(data);
	const isDismissed = loading || !!error || !!treatmentPillDismissed;

	// set dismiss state for EA pill
	const handleDismiss = useCallback(() => {
		if (
			fg('edition_awareness_upsell_confluence_aa_fg') ||
			fg('edition_awareness_standard_upsell_confluence_ab_fg')
		) {
			setOnboardingState({
				key: EDITION_AWARENESS_STANDARD_TO_PREMIUM_EXPERIMENT_PILL_DISMISSED,
				value: 'true',
			});
		}
	}, [setOnboardingState]);

	return {
		isDismissed,
		handleDismiss,
	};
};
