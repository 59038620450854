import type { FC } from 'react';
import React from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';

import { LinkItem } from '@atlaskit/menu';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';

import { ThemePopupMenu } from '@confluence/theming/entry-points/ThemePopupMenu';

import type { PopupMenuItem } from './PopupMenuItem';

const ThemeMenuItemComponent: FC<PopupMenuItem & WrappedComponentProps> = ({
	item,
	isSelected,
	closeMenu,
	...linkItemProps
}) => {
	return (
		<ThemePopupMenu
			isOpen={isSelected}
			onSelected={closeMenu}
			closeMenu={linkItemProps.onClick}
			trigger={({ triggerRef, ...triggerProps }) => (
				<LinkItem
					{...triggerProps}
					{...linkItemProps}
					isSelected={isSelected}
					iconAfter={<ChevronRightIcon spacing="spacious" label="" />}
					ref={triggerRef}
				>
					{item.content}
				</LinkItem>
			)}
		/>
	);
};

export const ThemeMenuItem = injectIntl(ThemeMenuItemComponent);
