import gql from 'graphql-tag';

export const HardDeleteSpaceMutation = gql`
	mutation HardDeleteSpaceMutation($spaceKey: String!) {
		hardDeleteSpace(spaceKey: $spaceKey) {
			taskId
			success
		}
	}
`;
