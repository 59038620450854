import { useMemo } from 'react';

// duplicate of the same fn in platform
export const useBuildLearnMoreLink = (baseUrl?: string | null): string => {
	const FALLBACK_URL = '/admin/billing';

	return useMemo(() => {
		if (!baseUrl) {
			return FALLBACK_URL;
		}

		try {
			const url = new URL(baseUrl);

			const isHams = url.pathname.includes('change-edition');

			if (isHams) {
				// remove "/<edition>" at the end of the path
				url.pathname = url.pathname.split('/').slice(0, -1).join('/');
			} else {
				url.searchParams.delete('offeringName');
				url.searchParams.delete('offeringname');
			}

			return url.toString();
		} catch (err) {
			return FALLBACK_URL;
		}
	}, [baseUrl]);
};
