import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useNav4OptOut } from '@confluence/nav4-enabled';
import { useNav4FeedbackCollectorActions } from '@confluence/nav4-feedback';

import type { FilteredItems } from './useWebItemMapper';

export const useNav4OptOutMenuItem = (): FilteredItems => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { nav4OptOut, updateNav4OptOut } = useNav4OptOut();
	const { openOptOutModal } = useNav4FeedbackCollectorActions();

	const optInAction = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				source: 'nav4OptOut',
				actionSubjectId: 'turnOnNewNavigation',
			},
		}).fire();

		void updateNav4OptOut(false);
	};

	return {
		heading: i18n.navigation,
		items: [
			nav4OptOut
				? {
						key: 'nav4-opt-out',
						action: optInAction,
						content: <FormattedMessage {...i18n.turnOn} />,
					}
				: {
						key: 'nav4-opt-out',
						action: () => openOptOutModal(),
						content: <FormattedMessage {...i18n.switchBack} />,
					},
		],
	};
};

const i18n = defineMessages({
	navigation: {
		id: 'nav-menus.nav4-opt-out.navigation',
		defaultMessage: 'Navigation',
		description:
			'Text for the heading of the menu item which allows the user to opt-out of the new navigation',
	},
	switchBack: {
		id: 'nav-menus.nav4-opt-out.switch-back',
		defaultMessage: 'Switch back to the old navigation',
		description:
			'Text for menu button which appears in the Profile dropdown menu. On click it opens a dialog which allows the user to opt-out of the new navigation experience, switching back to the previous version of the navigation.',
	},
	turnOn: {
		id: 'nav-menus.nav4-opt-out.turn-on',
		defaultMessage: 'Turn on the new navigation',
		description:
			'Text for menu button which appears in the Profile dropdown menu. On click it opts the user in to the new navigation experience.',
	},
});
