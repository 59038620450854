import gql from 'graphql-tag';

export const TrashedPersonalSpacesQuery = gql`
	query TrashedPersonalSpacesQuery($creatorAccountIds: [String!]) {
		spaces(type: "PERSONAL", creatorAccountIds: $creatorAccountIds, status: "TRASHED") {
			nodes {
				alias
				key
				id
			}
		}
	}
`;
