import { LoadableLazy } from '@confluence/loadable';

export const ThemePopupMenu = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ThemePopupMenu" */ '../src/ThemePopupMenu/ThemePopupMenu'
			)
		).ThemePopupMenu,
});
