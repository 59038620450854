import gql from 'graphql-tag';

export const IsTenantPaidAndUpgradable = gql`
	query confluenceEditionAwarenessQuery(
		$cloudId: ID!
		$productKey: String!
		$trialOfferingName: String!
		$entitlementFilter: CommerceEntitlementFilter
		$shouldCheckForPaidOffering: Boolean!
	) {
		tenantContexts(cloudIds: [$cloudId])
			@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
			entitlementInfo(hamsProductKey: $productKey) {
				paidEntitlement: entitlement(where: { inTrialOrPreDunning: false })
					@include(if: $shouldCheckForPaidOffering) {
					experienceCapabilities {
						changeOfferingV2(offeringName: $trialOfferingName) {
							isAvailableToUser
							experienceUrl
						}
					}
					subscription {
						accountDetails {
							invoiceGroup {
								invoiceable
							}
						}
					}
				}
				entitlement(where: $entitlementFilter) {
					preDunning {
						status
					}
					subscription {
						trial {
							endTimestamp
						}
					}
				}
			}
		}
	}
`;
