import type { Action } from 'react-sweet-state';
import { createHook, createStore, createActionsHook } from 'react-sweet-state';

interface DialogState {
	isOpen?: boolean;
	touchpointId?: string;
}

interface ModalPropState {
	learnMoreUrl: string;
	experienceUrl: string;
}

const initialState: DialogState & ModalPropState = {
	isOpen: false,
	learnMoreUrl: '',
	experienceUrl: '',
};

const actions = {
	showModal:
		({ touchpointId }: DialogState): Action<DialogState> =>
		({ setState }) => {
			setState({ isOpen: true, touchpointId });
		},
	closeModal:
		(): Action<DialogState> =>
		({ setState }) => {
			setState({ isOpen: false });
		},
	setModalProps:
		({ learnMoreUrl, experienceUrl }: ModalPropState): Action<ModalPropState> =>
		({ setState }) => {
			setState({
				learnMoreUrl,
				experienceUrl,
			});
		},
};
const store = createStore({
	initialState,
	actions,
	name: 'editionAwarenessStandardToPremiumModalStore',
});
export const useConfluenceStandardToPremiumModalActions = createActionsHook(store);
export const useConfluenceStandardToPremiumModal = createHook(store);
