import { LoadableAfterPaint } from '@confluence/loadable';

export const LazyAddPersonalSpaceDialog = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AddPersonalSpaceDialog" */ './AddPersonalSpaceDialog'
			)
		).AddPersonalSpaceDialog,
});
